import React from 'react'
import OnVisible from 'react-on-visible'
import styled from 'styled-components'

import Layout from '../components/layout'
import SEO from '../components/seo'
import Hero from '../components/hero'
import Boilerplate from '../components/boilerplate'

import hexagonIllustration from '../images/illustration-hexagon.svg'
import qualityIllustration from '../images/illustration-quality.svg'
import imagePolygonCopacking from '../images/image-polygon-copacking.png'
import imagePolygonLogistic from '../images/image-polygon-logistic.png'
import imagePolygonPos from '../images/image-polygon-pos.png'

import GHE from '../files/ghe.pdf'
import GHEbestaetigung from '../files/ghe-bestaetigung.pdf'
import Stellenanzeige from '../files/stellenanzeige-sachkundige-person.pdf'
import StellenanzeigePMQM from '../files/stellenanzeige-projektmanager-qualitaetsmanagement.pdf'

const LinkButton = styled.a`
  background-color: ${props => props.theme.colorPrimary};
  color: white;
  text-decoration: none;
  padding: 10px;
  transition: 0.5s;

  &:hover {
    background-color: ${props => props.theme.colorGrayDark};
  }
`

const IndexPage = () => {
  return (
    <Layout>
      <SEO
        title="Co-Packing, Lagerung &amp; Logistik und POS-Entwicklung"
        keywords={['creopharm', 'pharma', 'co-packing']}
      />

      <Hero />

      <section id="about">
        <OnVisible
          percent={20}
          className="container medium-padding animated fromBottom"
        >
          <div className="column">
            <h2 style={{ marginTop: 0 }}>
              Kompetentes und zuverlässiges Co-Packing Ihrer Arzneimittel,
              Medizinprodukte und Kosmetika
            </h2>
            <p>
              Jeder einzelne Prozessschritt basiert hierbei auf den Vorschriften
              des deutschen Arzneimittelgesetzes sowie den europäischen GDP
              Leitlinien. Durch den Erhalt der Großhandelserlaubnis gemäß §52a
              AMG wissen wir genau, worauf es beim Umgang mit pharmazeutischer
              Ware ankommt. In unseren neu errichteten Räumlichkeiten
              gewährleisten wir die ordnungsgemäße Konfektionierung Ihrer
              Arzneimittel, Medizinprodukte und Kosmetika. Die
              Verpackungsqualifizierung sowie die Transportvalidierung, jeweils
              unter der Berücksichtigung von Temperatur-Mapping und weiteren
              gesetzlichen Vorgaben, zählen ebenfalls zu unserem Service.
            </p>
            <p>
              <LinkButton
                href={GHE}
                style={{ marginBottom: '10px' }}
                target="_blank"
                rel="noopener"
              >
                Großhandelserlaubnis gemäß AMG
              </LinkButton>
              <LinkButton href={GHEbestaetigung} target="_blank" rel="noopener">
                Übereinstimmung des Großhandels gemäß GDP
              </LinkButton>
            </p>
          </div>
          <div className="column">
            <img src={hexagonIllustration} alt="Illustration Hexagon" />
          </div>
        </OnVisible>
      </section>

      <section id="services">
        <div id="copacking" className="background-gray">
          <OnVisible
            percent={20}
            className="container medium-padding animated fromBottom"
          >
            <div className="column image">
              <img src={imagePolygonCopacking} alt="Co-packing" />
            </div>
            <div className="column text">
              <h3 style={{ marginTop: 0 }}>
                Konfektionierung und Co-Packing: Sorgsamer Umgang mit Ihren
                Markenprodukten
              </h3>
              <p style={{ marginBottom: '0' }}>
                Die fachgerechte und sorgfältige Bestückung von
                Point-of-Sale-Displays sind unsere Stärken für die
                Pharma-Branche. Hierbei achten wir auf eine detaillierte
                Einhaltung der gesetzlichen Dokumentationspflicht. Die stetige
                Überprüfung unserer Arbeitsschritte sowie unser strenges
                Qualitätsmanagement zeichnen uns aus und stellen sicher, dass
                wir weder die Ansprüche unserer Kunden noch die rechtlichen
                Vorschriften aus dem Auge verlieren.
              </p>
            </div>
          </OnVisible>
        </div>
        <div id="logistic">
          <OnVisible
            percent={20}
            className="container medium-padding animated fromBottom"
          >
            <div className="column image">
              <img src={imagePolygonLogistic} alt="Logistic" />
            </div>
            <div className="column text">
              <h3 style={{ marginTop: 0 }}>
                Lagerung und Logistik: fachspezifische und perfekte Betreuung
                bis zum POS
              </h3>
              <p style={{ marginBottom: '0' }}>
                Unsere modernen Räumlichkeiten erfüllen höchste
                Qualitätsstandards: Sorgfalt, Sauberkeit, Pest-Control-Verfahren
                und Temperatur-Mapping sind für uns selbstverständlich. Um Sie
                bis ans Ende der Produktionskette zu begleiten, übernehmen wir
                nach der Konfektionierung und Verpackung auch die qualifizierte
                Lagerung, sowie die fachgerechte Logistik für Sie. Im nationalen
                wie auch internationalen Versand liefern wir Ihre Produkte in
                einwandfreiem Zustand an Ihr Zentrallager, oder direkt an
                Apotheken, Drogeriemärke und Einzelhändler.
              </p>
            </div>
          </OnVisible>
        </div>
        <div id="pos" className="background-gray">
          <OnVisible
            percent={20}
            className="container medium-padding animated fromBottom"
          >
            <div className="column image">
              <img src={imagePolygonPos} alt="POS" />
            </div>
            <div className="column text">
              <h3 style={{ marginTop: 0 }}>
                One-stop-supplier: Kreativität und Konfektionierung aus einer
                Hand
              </h3>
              <p>
                Unser Service geht noch einen Schritt weiter: Als Tochter der
                Creodis GmbH greifen wir auf mehr als 10 Jahre Erfahrung in der
                Kreation modernster POS-Displays zurück. Wir hören genau zu und
                verstehen den Anspruch, den Sie und Ihre Kunden an das Produkt
                haben. So entwickeln wir außergewöhnliche
                Point-of-Sale-Lösungen, welche den Mehrwert Ihrer Produkte in
                Sekundenschnelle vermitteln – und damit die Vertrauensbasis
                zwischen dem Endkunden und Ihrem Produkt entscheidend prägen.
              </p>
              <p>
                Von der Kreation samt Entwicklung, Musterbau und schließlich der
                Fertigung übers Co-Packing bis hin zur Lagerung und Logistik
                platzieren wir alle Schritte unter einem Dach. So optimieren wir
                die Wertschöpfungskette und minimieren damit Transportwege.
                Zudem handeln wir schnell, flexibel und sind immer darauf
                bedacht, möglichst kosteneffizient zu agieren.
              </p>
              <p>
                Ihre Wünsche stehen bei uns im Vordergrund. Daher steht Ihnen
                während des gesamten Projekts ein fester Ansprechpartner zur
                Seite und gewährleistet einen optimalen Ablauf und rasche
                Abstimmungsphasen.
              </p>

              <p style={{ marginBottom: '0' }}>
                <LinkButton
                  href="https://www.creodis.de"
                  target="_blank"
                  rel="noopener"
                >
                  Mehr über Creodis erfahren
                </LinkButton>
              </p>
            </div>
          </OnVisible>
        </div>
      </section>

      <section id="quality">
        <OnVisible
          percent={20}
          className="container medium-padding animated fromBottom"
        >
          <div className="column">
            <img src={qualityIllustration} alt="Illustration Quality" />
          </div>
          <div className="column">
            <h3 style={{ marginTop: 0 }}>Unser Qualitätsversprechen</h3>
            <p style={{ marginBottom: 0 }}>
              Medizinische Waren müssen Vertrauen schaffen. Die Beziehung
              zwischen Kunde und Produkt steht bei Creopharm daher im Fokus
              jeder Entwicklungsphase. So konfektionieren wir nicht nur
              Warenpräsentationssysteme nach geltenden Qualitätsbestimmungen,
              sondern gestalten auch authentische POS-Displays für die
              Pharma-Branche. In enger Absprache mit unseren Kunden und unter
              höchstem Anspruch an uns selbst fertigen wir so individuelle
              Komplett-Lösungen, die überzeugen.
            </p>
          </div>
        </OnVisible>
      </section>

      <section id="career" className="background-gray">
        <OnVisible
          percent={20}
          className="container medium-padding animated fromBottom"
          style={{ textAlign: 'center', maxWidth: '760px' }}
        >
          <h3 style={{ marginTop: 0 }}>Kommen Sie in unser Team!</h3>
          {/* <p>
            Wir suchen aktuell eine <strong>Sachkundige Person</strong> nach §
            13 AMG (m/w/d). Die Stellenbeschreibung können Sie{' '}
            <a href={Stellenanzeige} target="_blank" rel="noopener">
              hier einsehen
            </a>
            .
          </p>
          <p>
            Wir haben Ihr Interesse geweckt? Dann freuen wir uns auf Ihre
            (Initiativ-) Bewerbung, sowohl für den kaufmännischen als auch für
            den gewerblichen Bereich.
          </p>
          <p>Bitte melden Sie sich bei:</p>
          <p style={{ marginBottom: 0 }}>
            Andrea Gustenberg
            <br />
            Mail:{' '}
            <a href="mailto:a.gustenberg@creodis.de">a.gustenberg@creodis.de</a>
          </p>
          <hr
            style={{
              height: '1px',
              backgroundColor: 'rgb(196, 196, 196)',
              border: 'none',
              margin: '30px 0',
            }}
          /> */}
          {/* <p>
            Wir suchen einen{' '}
            <strong>Projektmanager Qualitätsmanagement </strong> zum
            nächstmöglichen Zeitpunkt. Sie punkten mit Qualitätsversprechen?
            Dann schauen Sie gleich in unser{' '}
            <a href={StellenanzeigePMQM} target="_blank" rel="noopener">
              Job-Angebot
            </a>
            .
          </p>
          <p>
            Bei Fragen wenden Sie sich bitte an Melanie Jauken, 05401 8814 106
            oder <a href="mailto:m.jauken@creodis.de">m.jauken@creodis.de</a>
          </p> */}
          <p>
            Aktuell haben wir keine Stellen in der Creopharm zu besetzen. Wir
            freuen uns aber auf Initiativbewerbungen und sind immer interessiert
            an neuen Mitarbeitern, die unser großartiges Team ergänzen. Unsere
            aktuellen Job-Angebote in der Creodis finden Sie unter
            <a href="https://www.creodis.de/de/jobs">www.creodis.de/de/jobs</a>.
          </p>
          <p>
            Bei Fragen zur Creopharm steht Ihnen Mara Brinkmeyer <br />
            (05401 8814 105,{' '}
            <a href="mailto:m.brinkmeyer@creopharm.de">
              m.brinkmeyer@creopharm.de
            </a>
            ) gern zur Verfügung.
          </p>
        </OnVisible>
      </section>

      <Boilerplate lang="de" />
    </Layout>
  )
}

export default IndexPage
